// Offcanvas behaviour

let offcanvasParentnavitems = document.querySelectorAll('.mainnav ul .parent');

for (let parentNavitem of offcanvasParentnavitems) {
    parentNavitem.addEventListener('click', (e) => {
        if(window.innerWidth <= 1023) {
            if(!parentNavitem.classList.contains('opened')) {
                e.stopPropagation();
                e.preventDefault();

                for (let parentNavitem of offcanvasParentnavitems) {
                    parentNavitem.classList.remove('opened')
                }

                parentNavitem.classList.add('opened');
                if(parentNavitem.parentElement.parentElement.tagName.toLowerCase() === 'li' ) {
                    parentNavitem.parentElement.parentElement.classList.add('opened')
                }
            }
        }
    })
}

// Customer Slideset

let setCustomersSlideset = () => {
    let customerSlidesets = document.querySelectorAll('.customers')
    for (let customerSlideset of customerSlidesets) {
        let customerList = customerSlideset.querySelector('.customers__customerlist');
        let customerListItems = customerList.querySelectorAll('li');
        let prevButton = customerSlideset.querySelector('.customerlistnav__prev');
        let nextButton = customerSlideset.querySelector('.customerlistnav__next');
        let currentSlideIndicator = customerSlideset.querySelector('.customerlistnav__current');
        let totalSlidesIndicator = customerSlideset.querySelector('.customerlistnav__total');

        let currentSlide = 1;
        let totalSlides;

        if(window.innerWidth <= 767) {
            // Set SlideIndicatorTotalCount
            totalSlides = customerListItems.length / 2;
            totalSlidesIndicator.innerHTML = totalSlides;

            // Set List-Length
            customerList.style.width = customerListItems.length * 50 + "%";
            for (let customerListItem of customerListItems) {
                let itemWidth = customerListItems.length + "%";
                customerListItem.style.width = 'calc(' + itemWidth + ' - 10px  )'
            }

            // Set List-Position
            customerList.style.marginLeft = (currentSlide - 1) * -100 + "%";

        } else if (window.innerWidth > 767) {
            // Set SlideIndicatorTotalCount
            totalSlides = customerListItems.length / 4;
            totalSlidesIndicator.innerHTML = totalSlides;

            // Set List-Length
            customerList.style.width = customerListItems.length * 25 + "%";
            for (let customerListItem of customerListItems) {
                let itemWidth = customerListItems.length + "%";
                customerListItem.style.width = 'calc(' + itemWidth + ' - 10px  )'
            }

            // Set CurrentSlide to senseful-number in case of switching views
            if (currentSlide > totalSlides) {
                currentSlide = currentSlide / 2;
                console.log('wuhuuu')
            }

            // Set List-Position
            customerList.style.marginLeft = (currentSlide - 1) * -100 + "%";
        }
        // Navigation Events {
        prevButton.addEventListener('click', () => {
            if(currentSlide > 1) {
                currentSlide --;
            } else if (currentSlide === 1) {
                currentSlide = totalSlides;
            }
            currentSlideIndicator.innerHTML = currentSlide;
            customerList.style.marginLeft = (currentSlide - 1) * -100 + "%";
        })
        nextButton.addEventListener('click', () => {
            if(currentSlide < totalSlides) {
                currentSlide ++;
            } else if (currentSlide === totalSlides) {
                currentSlide = 1;
            }
            currentSlideIndicator.innerHTML = currentSlide;
            customerList.style.marginLeft = (currentSlide - 1) * -100 + "%";
        })
    }
}

window.addEventListener('resize', setCustomersSlideset);
document.addEventListener('DOMContentLoaded', setCustomersSlideset);

// Navtoggler
document.querySelector('.headerbar__navtoggler').addEventListener('click', function () {
    if (document.body.classList.contains('body--activenav')) {
        document.body.classList.remove('body--activenav');
    } else {
        document.body.classList.add('body--activenav');
    }
});

// Detect Firefox
if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1 || navigator.userAgent.toLowerCase().indexOf('chrome') > -1){
    document.querySelector('.headerbar').classList.add('firefoxclient');
}


// Detect Junggesellenabschiede in Hash

let detectJunggesellenpage = () => {

    let locationurl = window.location.href;
    let re = '#junggesellenabschiede';

    if (locationurl.includes(re)) {
        document.body.classList.add('junggesellenabschied--active')
    } else if (document.body.classList.contains('junggesellenabschied--active')) {
        document.body.classList.remove('junggesellenabschied--active')
    }
}

window.addEventListener('hashchange', detectJunggesellenpage);
// document.addEventListener('DOMContentLoaded', detectJunggesellenpage);
